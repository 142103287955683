
:root {
  --zIndexBannerContent: 1;
  --zIndexHeader: 2;
  --zIndexAnnounce: 5;
  --zIndexSearchBackdrop: 24;
  --zIndexSearchContent: 25;
  --zIndexModalBackdrop: 49;
  --zIndexModalContent: 50;
  --zIndexDropdownBackdrop: 54;
  --zIndexTooltip: 55;
  --zIndexCookieConsent: 60;
  --zIndexLoadingIndicator: 1000;
  --backgroundLoginLayout: #f8f8f8;
  --whiteFFF: #fff;
}

[theme="dark"] {
  --main-color: red;
}

// import bootstrap
@import "../../../../node_modules/bootstrap/dist/css/bootstrap.css";
@import "../../../../node_modules/bootstrap-vue/dist/bootstrap-vue.css";

// import plugin template
@import './light-bootstrap-dashboard.scss';
@import '../css/demo.css';
// components
@import './components/views/views.scss';
@import './components/layouts/layouts.scss';
@import './components/component.scss';


//client
@import './client/layout/default.scss';
@import './custom.scss';



.custom__page__myself {
  border-radius: 10px;
  color: red;
  button {
    margin: 0 5px;
    padding: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: #D6DADF;
  }
}
  .default-btn {
    min-width: 100px;
  }

  .word-keep-all {
    word-break: keep-all;
  }
  .word-break-word {
    word-break: break-word;
  }

.cursor-pointer {
  cursor: pointer;
}
//----------------------------------------------------

#app__container__wrapper {
  height: 100vh;
  min-height: -webkit-fill-available;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow:none !important;
}

.text-white-text {
  color: #ffffff;
}

.btn-w-100 {
  min-width: 100px;
}

.w-170 {
  width: 170px;
}

.g-5 {
  gap: 5px;
}

.default-border-content {
  background-color: #FFFFFF;
  border: 1px solid #E3E3E3;
  border-radius: 4px;
}


.is-invalid-feedback-message {
  display: block;
  margin-top: 0.25rem;
  color: #DC3545;
  font-size: 16px;
}

.border-validation-error {
  border-color: #dc3545;
}

.fs-16 {
  font-size: 16px;
}

.td-break-word {
  word-wrap: break-word;
  white-space: normal;
}

.td-break-word-all {
  word-break: break-all;
  white-space: normal;
}

.table-custom-middle-center {
  tr {
    td {
      align-items: center;
      vertical-align: middle;
    }
  }
}

.hint__text {
  color: #8993a4;
  font-size: 14px;
}
