$titleFormColor: #5e5873;
$imageBgColor: #f8f8f8;

.login__container__wrapper {
  display: flex;
  flex-basis: 100%;
  min-height: calc(var(--vh, 1vh)*100);
  width: 100%;
  overflow: hidden;

  &.auth__container {
    align-items: flex-start;   
  }
  .auth__content {
    width: 100%;
    position: relative;
    height: calc(var(--vh, 1vh)*100);

    .image__present {
      background-color: $imageBgColor; 
      opacity: 0;
      animation: fadeInLeft .5s forwards;
    }
  }

  .auth__form {
    background-color: var(--whiteFFF);
    animation: fadeInRight .5s forwards;
    opacity: 0;
    &-title {
      font-family: Montserrat, Helvetica, Arial, serif;
      color: $titleFormColor;
      font-size: 1.714em;
      @media screen and (max-width: 768px) {
        text-align: center;
        margin: 0;
      }
    }

    &-form__content {
      .form-group {
        label.control-label {
          font-size: 12px;
          @media screen and (max-width: 768px) {
            font-size: 14px;
          }
        }
        input {
          font-size: 14px;
        }
      }

      &-btn__submit {
        outline: none;
        border: none;
      }
    }
  }
}