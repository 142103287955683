
/*!

 =========================================================
 * Vue Light Bootstrap Dashboard - v2.0.0 (Bootstrap 4)
 =========================================================

 * Product Page: http://www.creative-tim.com/product/light-bootstrap-dashboard
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

@import "lbd/variables";
@import "lbd/mixins";

@import "lbd/typography";

// Core CSS
@import "lbd/misc"; // can comment
@import "lbd/sidebar-and-main-panel";
@import "lbd/inputs"; // can comment

@import "lbd/alerts";

@import "lbd/navbars";
@import "lbd/footers";

// Fancy Stuff
@import "lbd/plugins/animate";
@import "lbd/dropdown";
@import "lbd/cards"; // can comment
@import "lbd/chartist";
@import "lbd/responsive";
@import "lbd/bootstrap-switch";

// Nucleo Icons
@import "lbd/partial-nucleo-icons";

// Nucleo Icons
@import "lbd/partial-nucleo-icons";
