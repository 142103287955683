.bar-loading-1 {
  width: 45px;
  aspect-ratio: 1;
  --c: no-repeat linear-gradient(#000 0 0);
  background: 
    var(--c) 0%   50%,
    var(--c) 50%  50%,
    var(--c) 100% 50%;
  background-size: 20% 100%;
  animation: b1 1s infinite linear;
}
@keyframes b1 {
  0%  {background-size: 20% 100%,20% 100%,20% 100%}
  33% {background-size: 20% 10% ,20% 100%,20% 100%}
  50% {background-size: 20% 100%,20% 10% ,20% 100%}
  66% {background-size: 20% 100%,20% 100%,20% 10% }
  100%{background-size: 20% 100%,20% 100%,20% 100%}
}

.bar-loading-2 {
  width: 45px;
  aspect-ratio: 1;
  --c: no-repeat linear-gradient(#000 0 0);
  background: 
    var(--c) 0%   100%,
    var(--c) 50%  100%,
    var(--c) 100% 100%;
  animation: b2 1s infinite linear;
}
@keyframes b2 {
  0%  {background-size: 20% 100%,20% 100%,20% 100%}
  20% {background-size: 20% 60% ,20% 100%,20% 100%}
  40% {background-size: 20% 80% ,20% 60% ,20% 100%}
  60% {background-size: 20% 100%,20% 80% ,20% 60% }
  80% {background-size: 20% 100%,20% 100%,20% 80% }
  100%{background-size: 20% 100%,20% 100%,20% 100%}
}

.bar-loading-3 {
  width: 45px;
  aspect-ratio: 1;
  background: 
    linear-gradient(#0000 calc(1*100%/6),currentColor 0 calc(3*100%/6),#0000 0) left   bottom,
    linear-gradient(#0000 calc(2*100%/6),currentColor 0 calc(4*100%/6),#0000 0) center bottom,
    linear-gradient(#0000 calc(3*100%/6),currentColor 0 calc(5*100%/6),#0000 0) right  bottom;
  background-size: 20% 600%;
  background-repeat: no-repeat;
  animation: b3 1s infinite linear;
}
@keyframes b3 {
    100% {background-position: left top,center top,right top }
}

.bar-loading-4 {
  width: 45px;
  aspect-ratio: 1;
  --c: no-repeat linear-gradient(#000 calc(50% - 10px),#0000 0 calc(50% + 10px),#000 0);
  background: 
    var(--c) 0%   100%,
    var(--c) 50%  100%,
    var(--c) 100% 100%;
  background-size: 20% calc(200% + 20px);
  animation:b4 1s infinite linear;
}
@keyframes b4 {
    33%  {background-position: 0% 50%,50% 100%,100% 100%}
    50%  {background-position: 0%  0%,50%  50%,100% 100%}
    66%  {background-position: 0%  0%,50%   0%,100%  50%}
    100% {background-position: 0%  0%,50%   0%,100%   0%}
}

.bar-loading-5 {
  width: 45px;
  aspect-ratio: .75;
  --c: no-repeat linear-gradient(#000 0 0);
  background: 
    var(--c) 0%   100%,
    var(--c) 50%  100%,
    var(--c) 100% 100%;
  background-size: 20% 65%;
  animation: b5 1s infinite linear;
}
@keyframes b5 {
  20% {background-position: 0% 50% ,50% 100%,100% 100%}
  40% {background-position: 0% 0%  ,50% 50% ,100% 100%}
  60% {background-position: 0% 100%,50% 0%  ,100% 50% }
  80% {background-position: 0% 100%,50% 100%,100% 0%  }
}

.bar-loading-6 {
  width: 45px;
  aspect-ratio: .75;
  --c: no-repeat linear-gradient(#000 0 0);
  background: 
    var(--c) 0%   50%,
    var(--c) 50%  50%,
    var(--c) 100% 50%;
  background-size: 20% 50%;
  animation: b6 1s infinite linear;
}
@keyframes b6 {
  20% {background-position: 0% 0%  ,50% 50% ,100% 50% }
  40% {background-position: 0% 100%,50% 0%  ,100% 50% }
  60% {background-position: 0% 50% ,50% 100%,100% 0%  }
  80% {background-position: 0% 50% ,50% 50% ,100% 100%}
}

.bar-loading-7 {
  width: 45px;
  aspect-ratio: .75;
  --c: no-repeat linear-gradient(#000 0 0);
  background: 
    var(--c) 0%   50%,
    var(--c) 50%  50%,
    var(--c) 100% 50%;
  animation: b7 1s infinite linear alternate;
}
@keyframes b7 {
  0%  {background-size: 20% 50% ,20% 50% ,20% 50% }
  20% {background-size: 20% 20% ,20% 50% ,20% 50% }
  40% {background-size: 20% 100%,20% 20% ,20% 50% }
  60% {background-size: 20% 50% ,20% 100%,20% 20% }
  80% {background-size: 20% 50% ,20% 50% ,20% 100%}
  100%{background-size: 20% 50% ,20% 50% ,20% 50% }
}

.bar-loading-8 {
  width: 45px;
  aspect-ratio: .75;
  --c:no-repeat linear-gradient(#000 0 0);
  background: 
    var(--c) 0%   100%,
    var(--c) 50%  100%,
    var(--c) 100% 100%;
  background-size: 20% 65%;
  animation:b8 1s infinite linear;
}
@keyframes b8 {
  16.67% {background-position: 0% 0%  ,50% 100%,100% 100%}
  33.33% {background-position: 0% 0%  ,50% 0%  ,100% 100%}
  50%    {background-position: 0% 0%  ,50% 0%  ,100% 0%  }
  66.67% {background-position: 0% 100%,50% 0%  ,100% 0%  }
  83.33% {background-position: 0% 100%,50% 100%,100% 0%  }
}

.bar-loading-9 {
  width: 45px;
  aspect-ratio: .75;
  --c: no-repeat linear-gradient(#000 0 0);
  background: 
    var(--c) 0%   50%,
    var(--c) 50%  50%,
    var(--c) 100% 50%;
  background-size: 20% 60%;
  animation:b9 1s infinite;
}
@keyframes b9 {
  33% {background-position: 0% 0%  ,50% 100%,100% 0%  }
  66% {background-position: 0% 100%,50% 0%  ,100% 100%}
}

.bar-loading-10 {
  height: 45px;
  aspect-ratio: 1.2;
  --c:no-repeat repeating-linear-gradient(90deg,#000 0 20%,#0000 0 40%);
  background: 
    var(--c) 50% 0,
    var(--c) 50% 100%;
  background-size: calc(500%/6) 50%;
  animation: b10 1s infinite linear;
}
@keyframes b10 {
  33%  {background-position: 0   0   ,100% 100%}
  66%  {background-position: 0   100%,100% 0   }
  100% {background-position: 50% 100%,50%  0   }
}