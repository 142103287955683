.setting__menu__wrapper {
  display: flex;
  flex-direction: row;
  gap: 15px;
  flex-wrap: nowrap;
  
  .left__menu, .right__menu {
    width: 40%;
    height: 400px;
  }
  .action__menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    height: 100%;
  }
}

.menu__wrapper__container {
  height: 100%;
  &-content {
    overflow-y: auto;
    height: 100%;
    border: 1px solid #dfdfdf;
    padding: 8px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: #e4e4e4;
    }

    &::-webkit-scrollbar-thumb {
      border: 1px solid rgba(0, 0, 0, 0.18);
      background-color: #8070d4;
    }
  }
}

.item__menu__wrapper {
  display: block;
  margin-bottom: 5px;
  border-radius: 5px;
  height: 100%;
  
  &-content {
    background-color: #d1ecf1;
    font-weight: 450;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 5px 8px;
    border-radius: 8px;
    font-size: 14px;
    &.pointer {
      cursor: pointer;
    }
    &:hover {
      background-color: #aadee8;
    }

    .icon__remove {
      transition: 0.3s;
      color: #ff4a55;
      cursor: pointer;
      &:hover {
        color: #d90419;
      }
    }
  }
}