.use__dropdown__wrapper {
  position: relative;
  display: inline-block;

  .dropdown__menu__icon {
    padding: 4px 8px;
    text-align: center;
    transition: transform .2s ease;
    font-size: 14px;
    position: absolute; 
    right: 0; 
    top: 0; 
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    .icon__dropdown__menu {
      transition: all .5s ease-in-out;
  
      &.active {
        animation-name: rotationUp;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
      }
  
      &.inactive {
        animation-name: rotationDown;
        animation-duration: 0.5s;
        animation-fill-mode: forwards;
      }
    }
  }

  .organization__dropdown__content {
    cursor: pointer;
    background-color: #FFFFFF;
    border: 1px solid #E3E3E3;
    border-radius: 4px;
    color: #565656;
    padding: 8px 12px;
    min-height: 40px;
    box-shadow: none;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    .dropdown__item {
      display: flex;
      gap: 5px;
      padding: 5px;
      border-radius: 3px;
      align-items: center;
      background-color: #68acd5;
      margin: 5px;
      font-size: 12px;
      color: #fff;
      &-organization__name {
        border-right: .5px solid #fff;
        padding-right: 5px;
      }
      &-icon__remove {
        cursor: pointer;
        outline: 0;
        border: none;
        background-color: transparent;
        padding: 0;
        
        &:hover {
          color: #FF4A55 !important;
        }
      }
    }
  }

  .content__dropdown {
    position: absolute;
    background-color: #ffffff;
    min-width: 100%;
    z-index: 5;
    height: 0px;
    overflow: auto;
    border-top: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    &.show__dropdown {
      display: block;
      padding: 8px;
      max-height: 300px;
      height: auto;
      border: 1px solid #E3E3E3;
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: #e4e4e4;
    }

    &::-webkit-scrollbar-thumb {
      border: 1px solid rgba(0, 0, 0, 0.18);
      background-color: #8070d4;
    }
    &.danger-fetch-error {
      border-color: #dc3545;
    }
  }
}

.account__setting__organization__wrapper {
  height: 300px;
  overflow: auto;
  padding: 8px;
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: #e4e4e4;
  }

  &::-webkit-scrollbar-thumb {
    border: 1px solid rgba(0, 0, 0, 0.18);
    background-color: #8070d4;
  }
  &.danger-fetch-error {
    border-color: #dc3545;

  }
}

@keyframes rotationUp {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}

@keyframes rotationDown {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
}