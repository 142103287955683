.df__name__component__input {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 5px;

  &-title__input {
    white-space: nowrap !important;
    width: 20%;
  }

  &-input__form {
    width: 80%;
  }
}