$heightNavbar: 6rem;
$navbarBgColor: #F2F2F2;
$footerBgColor: #54595f;
$contentBgColor: #f2f2f2;
$redPrimaryColor: #ff0000;

.navbar__container__wrapper {
    height: $heightNavbar;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 25;
    background-color: $navbarBgColor;
    border-style: solid;
    border-width: 0px 0px 1px 0px;
    border-color: #D6DADF;

    &-header__content {
        display: flex;
        height: 100%;
        width: 100%;
        align-items: center;
        padding: 5px 0;
        justify-content: space-between;

        .logo__box {
            width: 15%;
            height: 100%;
            display: flex;
            align-items: center;
        }

        .title__box {
            width: 65%;
            display: flex;
            text-align: center;
            align-items: center;
            font-weight: bold;
            justify-content: center;
            letter-spacing: 5px;
        }

        .menu__box {
            width: 15%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            color: #7A7A7A;

            p {
                line-height: 0.9em;
                font-weight: 600;
            }

            .control__sidebar {
                outline: none;
                border: none;
                background-color: transparent;

                .icon {
                    color: #7A7A7A;
                    font-size: 30px;
                    cursor: pointer;
                    transition: all .5s ease;
                    &:hover {
                        color: $redPrimaryColor;
                    }
                }
            }

        }
    }
    @media screen and (min-width: 901px) {
        .member-name{
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            min-width: 100px;
            line-height: 1.2rem !important;
            text-align: right;
            padding-right: 10px;
        }
    }

    @media screen and (max-width: 900px) {
        .container-sm {
            padding-right: 12px;
            padding-left: 12px;
        }
        .logo__box {
            img {
                height: auto;
                width: 70px;
            }
        }
        .title__box {
            font-size: 14px;
        }
        .menu__box {
            gap: 8px;
            width: 7%;
            p {
                font-size: 14px;
                display: none;
            }
        }
    }
}

.main__container__wrapper {
    display: flex;
    position: relative;
    margin-top: 6rem;
    min-height: calc(100vh - 6rem);
    min-width: 0;
    background-color: $contentBgColor;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;

    &-content {
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    @media screen and (max-height: 900px) {
        min-height: calc(100vh);
    }
}


.footer__container__wrapper {
    height: 3rem;
    width: 100%;
    background-color: $footerBgColor;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 11px;
    padding: 5px 0;
    font-weight: bold;
    color: white;
    letter-spacing: .5px;
}

.base__content__page {
    width: 100%;
    background-color: transparent;
    position: relative;

    &-primary__content {
      padding-top: 20px;
      padding-bottom: 20px;

      .container-sm {
        padding-right: 8px;
        padding-left: 8px;
      }

      .primary__background__color {
        background-color: #f2f2f2;
      }
      
      &-logo__banner__wrapper, &-content__wrapper {
        align-items: stretch;
      }

      &-logo__banner__wrapper {
        margin: 0px;

        @media screen and (max-width: 760px) {
          padding: 0;
          margin-top: 15px;
        }

        @media screen and (max-width: 767.8px){
          padding-left: 0px;
        }

      }
    }
  }